.Button {
  cursor: pointer;
  opacity: 0.9;
}

.Button:hover {
  opacity: 1;
}

.Button--text {
  background-color: #ccc;
  color: black;
  padding: 0.5em 0.9em;
  border: none;
  border-radius: 4px;
}

.Button--textDanger {
  background-color: #f44336;
}

.Button--textAccept {
  background-color: #007bff;
  color: #fff;
  font-weight: 400;
}

.Button--isIcon {
  opacity: 0.6;
}

